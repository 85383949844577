
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Take full height of its parent */
  background: url('asset/pattern_web.png') center center fixed; /* Fixed background pattern */
  background-size: repeat; /* Cover the entire container */
  font-family: Arial, sans-serif;
  border-radius: 10px 10px 0 0; /* Match the border radius of the sliding container */
}


/* Messages Container */
.messages-container {
  flex-grow: 1; /* Allow the messages container to take available space */
  overflow-y: auto; /* Enable scrolling for messages */
  padding: 12px;
  background-color: transparent
}

/* Messages List */
.messages-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Ensure messages start at the bottom */
}

/* Messages List */


  
  
  /* Header Styles */
  /* .chat-header {
    flex-grow: 1;
    display: flex;
    height: '80px';
    align-items: center;
    padding: 15px;
  } */
  /* Chat Header */
.chat-header {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 15px;
  /* background-color: rgba(0, 0, 0, 0.5); Semi-transparent background */
  color: white;
  border-radius: 10px 10px 0 0; /* Match the container's border radius */
}
  
  .profile-pic {
    width: 30px;
    height: 30px;
    border-radius: 10%;
    margin-right: 10px;
  }
  
  .user-info h2 {
    margin: 0;
    font-size: 16px;
  }
  
  .user-info p {
    margin: 0;
    font-size: 14px;
    color: #b5b1b1;
  }
  
  
  /* Message Styles */
  .message {
    padding: 10px;
    margin: 5px 0;
    border-radius: 15px;
    max-width: 60%;
    position: relative;
  }
  
  .message.right {
    align-self: flex-end;
    background-color: #0052cc;
    color: white;
  }
  
  .message.left {
    align-self: flex-start;
    background-color: #ffd966;
    color: black;
  }
  
  .message img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  /* Status Indicator */
  .status {
    font-size: 0.75rem;
    color: gray;
    text-align: right;
    margin-top: 5px;
  }
  
  /* Input Container */
  .input-container {
    display: flex;
    padding: 10px;
    background-color: #fafafa;
    border-top: 1px solid #ddd;
  }
  
  .input-container input[type="text"] {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  
  .input-container button {
    padding: 10px 15px;
    border: none;
    background-color: #0052cc;
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .input-container input[type="file"] {
    /* display: none; */
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    background-color: #0052cc;
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }
  


  