/* Container to hold everything */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    min-height: 100vh;
  }
  
  /* Header styling */
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    background-color: #fff; /* Solid background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for effect */
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .auth-buttons {
    display: flex;
  }
  
  .login-btn,
  .signup-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .profile-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    background-color: #e0f7fa;
    padding: 18px;
    border-radius: 5px;
  }
  
  .signup-btn {
    background-color: #e0f7fa;
    padding: 8px 16px;
    border-radius: 5px;
  }
  
  /* Main content styling */
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh; /* Adjust height as needed */
    background-image: url('../asset/background_test.jpg'); /* Background image */
    background-size: cover;
    background-position: center;
  }
  
  /* Transparent overlay for text */
  .overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.6); /* Semi-transparent background */
    border-radius: 10px;
  }
  
  .overlay h2 {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .address-input {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .address-options {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }
  
  .address-options a {
    color: #00796b;
    text-decoration: none;
  }
  