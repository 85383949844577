/* Dialog.css */
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dialog-box {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    position: relative;
  }
  
  .dialog-close {
    position: static;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    
    font-size: 20px;
    cursor: pointer;
  }
  