/* OrderHeader.css */

/* Container for the entire order header */
.order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    position: relative; /* Ensure header content is positioned correctly */
    z-index: 500; /* Set z-index for header */
  }
  
  /* Address section styling */
  .address-section {
    display: flex;
    align-items: center;
  }
  
  /* Icon and address text */
  .location-icon {
    font-size: 24px; /* Adjust icon size as needed */
    margin-right: 8px;
  }
  
  .address-text {
    font-size: 16px;
    color: #007bff; /* Blue color for the address text */
  }
  
  /* View order button styling */
  .view-order-wrapper {
    /* This will position the button by default */
    display: flex;
  }
  
  /* Button styling */
  .view-order-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffc107; /* Yellow background */
    color: #000000;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* High z-index to appear above other elements */
  }
  
  /* Order button contents */
  .order-count {
    background-color: #fff;
    color: #ffc107;
    font-size: 14px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .order-text {
    margin-right: 8px;
  }
  
  .order-price {
    font-size: 16px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    /* On smaller screens, move the view order button to the bottom */
    .view-order-wrapper {
      position: fixed; /* Fixed positioning to keep it at the bottom */
      bottom: 10px; /* Position it slightly above the bottom */
      left: 50%; /* Center horizontally */
      transform: translateX(-50%); /* Adjust to center */
      width: calc(100% - 40px); /* Adjust width with some margin */
      max-width: 500px; /* Maximum width */
      z-index: 1000; /* High z-index to ensure it's above other elements */
    }
  
    /* Adjust the button to be full width in the fixed position */
    .view-order-button {
      width: 100%;
    }
  
    /* Hide the address section on smaller screens if necessary */
    .address-section {
      display: none; /* Hide the address section on smaller screens */
    }
  }
  