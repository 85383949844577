/* src/Profile.css */
.profile-container {
    background-color: #f9f6f7;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    margin: 0 auto;
    position: relative;
    text-align: center; /* Center text content */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  
    /* Use flexbox to center the content */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .profile-title {
    margin: 20px 0;
    font-size: 24px;
  }
  
  .profile-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-info {
    margin-top: 20px;
  
    /* Center the text */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-name {
    font-size: 20px;
    font-weight: bold;
  }
  
  .profile-contact,
  .profile-email {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
  }
  
  .edit-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
  }
  