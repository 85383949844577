/* AddressForm.css */
.address-form {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

h3 {
  text-align: left;
}

.address-current {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;
}

.address-current i {
  margin-right: 8px;
  color: #ffc107;
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 14px;
}

.address-type {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.address-type label {
  display: flex;
  align-items: center;
}

input[type="radio"] {
  margin-right: 5px;
}

.save-button {
  width: 100%;
  padding: 10px;
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px;
}

.save-button:hover {
  background-color: #ffb300;
}


/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 95%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 80vh; /* Ensures the modal doesn't exceed 80% of the viewport height */
  overflow-y: auto;
}

.autocomplete-dropdown {
  border: 1px solid #ddd;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 10px;
  font-size: 14px;
}

.suggestion-item.active {
  background-color: #f0f0f0;
}

.powered-by-google {
  margin-top: 15px;
  font-size: 12px;
  color: #aaa;
}

.close-modal-btn {
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
}

.close-modal-btn:hover {
  opacity: 0.9;
}

/* Add this CSS rule to your styles to ensure the autocomplete dropdown appears on top */
.pac-container {
  z-index: 2000 !important; /* Adjust this value if necessary */
}