/* OrderTrackingPage.css */
.order-tracking-page {
    position: relative;
    width: 100%;
    height: 120vh; /* Make the container full screen */
  }
  
  /* Styling for the MapComponent */
  .order-tracking-page > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 100%;
    z-index: 1; /* Ensure the map is at the lowest layer */
  }
  
  /* Order details container overlay */
  .order-details-container {
    position: absolute;
    bottom: 0px; /* Adjust this to move the overlay up or down */
    left: 0;
    right: 0;
    width: 90%;
    height: 70%;   
    max-width: 500px;
    background: rgba(255, 255, 255, 0.9); /* Slightly transparent background */
    padding: 20px;
    border-radius: 20px;
    z-index: 2; /* Make sure this is on top of the map */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Style individual sections */
  .status {
    color: #04032B;
  }
  
  .order-details,
  .courier-info,
  .payment-method,
  .order-items-list {
    margin-bottom: 15px;
  }
  
  .details,
  .courier-details,
  .payment-details {
    margin-top: 10px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  