/* src/CompleteProfile.css */
.complete-profile-container {
    background-color: #f9f6f7;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .profile-title {
    margin-top: 20px;
    font-size: 24px;
  }
  
  .welcome-text {
    font-size: 20px;
    font-weight: bold;
  }
  
  .subtitle {
    font-size: 16px;
    color: #555;
  }
  
  .profile-image-container {
    margin: 20px 0;
    cursor: pointer;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .phone-number {
    font-size: 16px;
    color: #555;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .input-error {
    border-color: red;
  }
  
  .username-container {
    position: relative;
  }
  
  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .checking-username {
    font-size: 14px;
    color: #555;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #ffc107;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .yes-button {
    width: 30%;
    padding: 10px;
    background-color: #023602;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .no-button {
    width: 30%;
    padding: 10px;
    background-color: #4b180d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .logout-confirmation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding:'20px'
}