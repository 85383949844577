/* LocationSearchScreen.css */
.location-container {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .location-header h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .location-header p {
    font-size: 14px;
    color: #666;
  }
  
  .location-input {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .location-input i {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .location-input input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .share-location-button,
  .login-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Arial, sans-serif;
  }

  .confirm-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Arial, sans-serif;
    background-color: green;
    color: white;
  }

  
  
  .share-location-button {
    background: #e8f4ff;
    color: #007bff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login-button {
    background: #f0f0f0;
    color: #007bff;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
  }
  