/* OrderProgressBar.css */
.order-progress-container {
    width: 90%;
    padding: 6px;
    text-align: center;
  }
  
  .progress-bar-background {
    position: relative;
    width: 100%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #04032B; /* Change color to suit your theme */
    transition: width 0.3s ease;
  }

  
  .progress-icons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .stage-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .stage-icon .icon {
    font-size: 24px;
  }
  
  .stage-icon .label {
    margin-top: 5px;
    font-size: 12px;
    color: #777;
  }
  
  .stage-icon.active .icon {
    color: #4caf50; /* Active stage color */
  }
  
  .progress-status {
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
  }
  