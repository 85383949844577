.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.background-section {
  position: relative;
  z-index: 1;  /* Ensure the container content is above the pseudo-element */
}
.background-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; 
  background-image: url('./asset/pattern_web.png');
  background-repeat: repeat;
  background-position: center top;
  background-attachment: fixed;
  background-size: auto;
  background-blend-mode: screen;
  /* background-color: rgb(210, 210, 90); */
  opacity: 0.6;
}


.background-pattern {
  position: relative;
  z-index: 1;  /* Ensure the container content is above the pseudo-element */
}
/* background-image: url('shopping_pattern.jpeg');  Path to your pattern image */


.background-pattern::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;  /* Ensure the background image stays behind the content */
  background-image: url('https://firebasestorage.googleapis.com/v0/b/naslst.appspot.com/o/media%2Fshopping_pattern.jpeg?alt=media');
  background-repeat: repeat;
  background-position: center top;
  background-attachment: fixed;
  background-size: auto;
  background-blend-mode: screen;
  background-color: rgb(210, 210, 90);
  opacity: 0.2;  /* Set the desired opacity */
}

.image-container {
  position: relative;
  width: 400px;  /* Adjust according to the image size */
  height: auto;
}

.blend-image {
  display: block;
  width: 100%;
  height: auto;
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 215, 0, 0.3); /* Light gold color with 30% opacity */
  mix-blend-mode: screen; /* Apply screen blend mode */
}

.colorized-image {
  width: 300px; /* Set the width you want */
  height: auto;
  filter: sepia(1) hue-rotate(30deg) saturate(200%) brightness(120%);
}

.search-location-input {
  width: 600px;
  margin: auto;
 }

 .modal-overlay-pay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-pay {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
}
